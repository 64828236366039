<script>
import TablePage from '@/found/components/table_page';
import Form from '../form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    treeCode: String,
  },
  data() {
    return {
      params: {
        auditTemplateId: '',
      },
      flag: false,
    };
  },
  watch: {
    treeCode(val) {
      this.params.auditTemplateId = val;
      this.getList();
    },
  },
  async created() {
    await this.getConfigList('audit_and_verification_list');
  },
  methods: {
    // beforeGetList() {
    //   const { searchFormData } = this;
    //   let status = false;
    //   Object.keys(searchFormData).forEach((e) => {
    //     if (searchFormData[e] !== '') {
    //       status = true;
    //     }
    //   });
    //   if (status && this.params.auditTemplateId) {
    //     return true;
    //   }
    //   return false;
    // },
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'view') {
        // 详情
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '详情';
        this.openFull();
      }
    },
  },
};
</script>
