<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import sapOrderForm from './components/sap_order_form.vue'; // SAP单号信息
import acceptanceForm from './components/acceptance_form.vue'; // 验收单列表
import baseListForm from './components/base_list.vue'; // 基础信息列表

formCreate.component('sapOrderFormVos', sapOrderForm);
formCreate.component('acceptanceFormVos', acceptanceForm);
formCreate.component('baseList', baseListForm);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    const rule = await this.getFormRule('audit_and_verification_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.post('/kms/kmsauditform/query', { id: this.formConfig.id }).then((res) => {
        // 组装基础信息列表数据
        const arr = [{
          name: '数量',
          sapNum: res.result.sapQuantity,
          kaNum: res.result.acceptanceQuantity,
          differenceNum: res.result.compareQuantity,
        }, {
          name: '不含税金额',
          sapNum: res.result.sapAmountNot,
          kaNum: res.result.acceptanceAmountNot,
          differenceNum: res.result.compareAmountNot,
        }, {
          name: '含税金额',
          sapNum: res.result.sapAmount,
          kaNum: res.result.acceptanceAmount,
          differenceNum: res.result.compareAmount,
        }];
        this.setValue({
          ...res.result,
          baseList: arr,
        });
        this.row = res.result;
      });
    }
  },
};
</script>
